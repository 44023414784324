#project-detail-component {
    max-width: 1120px;
    margin: auto;
    width: 100%;

    .outer-container {
        background: #FFFFFF;
        box-shadow: 0px 4px 15px rgba(102, 102, 102, 0.2);
        border-radius: 8px;

        padding: 24px;

        .heading {
            font-family: 'ActualM';
            font-style: normal;
            font-size: 18px;
            color: #232323;
        }
    }

    .project-detail-account-component {
        background-color: #EEEEEE;
        display: flex;
        justify-content: space-between;

        #left {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 19px 0 0;
        }

        .left_project {

            #color {
                margin-top: 13px;
                font-family: 'ActualR';
                font-style: normal;
                font-size: 12px;
                letter-spacing: 0.25px;

            }

        }

        #hr_tag {
            width: 0px;
            border-left: 1px solid #AAAAAA;
            height: 55px;
            margin: 0 32px;

        }

        .left_project p {
            margin-top: 4px;
        }

        .left_project h4 {
            font-family: 'ActualM';
            font-style: normal;
            font-size: 20px;
            letter-spacing: 0.25px;
            color: #212121;

        }

        .heading_name_style {
            font-family: 'ActualR';
            font-style: normal;

            font-size: 16px;

            letter-spacing: 0.15px;


            color: #232323;


        }

        .left_project p {
            font-family: 'ActualR';
            font-style: normal;

            font-size: 14px;

            letter-spacing: 0.25px;

            color: #666666;

        }

        #ins {
            margin-top: 4px;
            font-family: 'ActualR';
            font-style: normal;
            font-size: 14px;


            letter-spacing: 0.25px;


            color: #666666;
        }

        #right_project {

            margin: 19px 24px 0px 0px
        }

        .MuiButtonBase-root.button-var2 {
            color: white;
            background: var(--color-2);
            text-transform: unset;
            max-width: 149px;
            width: 100%;
            height: 40px;
            margin: 11px 35px;
            font-size: 13px;
            letter-spacing: 0.1px;
            font-family: 'ActualR';
            box-shadow: none;
        }

    }

    .details_part2 {
        margin-top: 24px;
        padding: 0px;

        .icon {
            position: absolute;
            left: 196px;
        }

        .details_second_half {
            margin-top: 24px;
            padding: 24px;

            .first_dropdown {

                .first_dropdown_1 {

                    .dropdown_1 {
                        border: 1px solid #AAAAAA;
                        border-radius: 4px;
                        width: 100%;
                    }

                    .MuiFilledInput-input {
                        padding-top: 20px;
                    }

                    #demo-simple-select-standard {
                        padding-top: 20px;
                    }

                    .MuiFormControl-root {
                        width: 100%;
                    }

                    .MuiFilledInput-root {
                        background-color: #FFFFFF;
                        width: 100%;
                        font-family: 'ActualR';
                        font-size: 14px;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        border-bottom-left-radius: 4px;

                    }

                    .MuiFilledInput-root:before {
                        border-bottom: none;

                    }

                    .MuiFilledInput-root:after {
                        border-bottom: none;
                        background-color: #FFFFFF;

                    }

                    .MuiSelect-icon {
                        color: #5029DD;

                    }
                }

            }

            .first_dropdown_one {
                .first_dropdown_1 {
                    margin-top: 16px;

                    .btn-marg {
                        margin: 0px;
                        height: 35px;
                        width: auto;
                        border: none;
                    }

                    .text-style {
                        position: absolute;
                        top: 9px;
                        right: 14px;
                    }

                    #demo-simple-select-standard-label {
                        font-family: 'ActualR';
                        font-size: 12px;

                        color: #727373;
                        cursor: pointer;
                    }

                    .dropdown_1 {
                        border: 1px solid #AAAAAA;
                        border-radius: 4px;
                        // width: 100%;
                    }

                    #demo-simple-select-standard {
                        padding-top: 20px;
                    }

                    .MuiFormControl-root {
                        width: 100%;
                    }

                    .MuiFilledInput-root {
                        background-color: #FFFFFF;
                        width: 100%;
                        font-family: 'ActualR';
                        font-size: 14px;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        border-bottom-left-radius: 4px;


                    }

                    .MuiFilledInput-root:before {
                        border-bottom: none;

                    }

                    .MuiFilledInput-root:after {
                        border-bottom: none;
                        background-color: #FFFFFF;

                    }

                    .MuiSelect-icon {
                        color: #5029DD;

                    }
                }
            }

            .box {
                width: 100%;
                height: 116px;
                border: 1px solid #AAAAAA;
                border-radius: 4px;

                #box_text {
                    font-family: 'ActualR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    /* identical to box height, or 133% */

                    /* Color/ Neutral/ Neutral - 500 */

                    color: #727373;
                }

                .content_box {
                    padding: 5px 16px;
                }

                .box_middle_text {
                    font-family: 'ActualR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;

                    /* Color/ Primary/ Primary - 300 */

                    color: #232323;
                }
            }

            .box_2 {
                margin-top: 16px;
                width: 100%;
                height: 67px;
                border: 1px solid #AAAAAA;
                border-radius: 4px;

                #box_text {
                    font-family: 'ActualR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    /* identical to box height, or 133% */

                    /* Color/ Neutral/ Neutral - 500 */

                    color: #727373;
                }

                .content_box {
                    padding: 5px 16px 5px;
                }

                .box_middle_text {
                    font-family: 'ActualR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;

                    /* Color/ Primary/ Primary - 300 */

                    color: #232323;
                }
            }

            .link {
                font-family: 'ActualR';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                /* identical to box height */

                letter-spacing: 0.25px;

                color: #333333;
            }

            #deal {
                font-family: 'ActualR';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                margin-top: 5px;
                /* identical to box height */

                letter-spacing: 0.5px;

                /* Links / Info */

                color: #2266DD;
            }

            .second_text_link {
                margin-top: 16px;

                .link {
                    font-family: 'ActualR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    /* identical to box height */
                    letter-spacing: 0.25px;
                    color: #333333;
                }

                #deal {
                    font-family: 'ActualR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    margin-top: 5px;
                    /* identical to box height */
                    letter-spacing: 0.5px;
                    /* Links / Info */
                    color: #2266DD;
                }

            }

            .box_3 {
                width: 100%;
                height: 48px;
                border: 1px solid #AAAAAA;
                border-radius: 4px;

                .box_content {
                    padding: 5px 16px;

                    #value {
                        font-family: 'ActualR';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        /* identical to box height, or 133% */
                        /* Color/ Neutral/ Neutral - 500 */
                        color: #727373;
                    }

                    #dollar {
                        font-family: 'ActualR';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        /* Color/ Primary/ Primary - 300 */
                        color: #232323;

                    }
                }

            }

            .box_4 {
                width: 100%;
                height: 48px;
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                margin-top: 16px;

                .box_content {
                    padding: 5px 16px;

                    #value {
                        font-family: 'ActualR';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        /* identical to box height, or 133% */
                        /* Color/ Neutral/ Neutral - 500 */
                        color: #727373;
                    }

                    #dollar {
                        font-family: 'ActualR';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        /* Color/ Primary/ Primary - 300 */
                        color: #232323;

                    }
                }

                .time_date {
                    padding: 0px;
                    border: 0px;

                    ::webkit-calender-picker-icon {
                        color: #2266DD;
                    }
                }

            }
        }

        .left_button {
            display: flex;
            justify-content: flex-end;

            margin-bottom: 16px;

            .MuiButtonBase-root.button-var2_new {
                color: white;
                background: #48BC25;
                ;
                text-transform: unset;
                // width: 100%;
                // max-width: 149px;
                width: 149px;
                height: 40px;
                margin: 15px 15px;
                font-size: 13px;
                letter-spacing: 0.1px;
                font-family: 'ActualR';
                box-shadow: none;
            }

        }

    }


    .left-side-component {
        margin-top: 24px;
        padding: 0px;

        .project-detail {
            margin-top: 24px;
            padding: 24px;

            .icon {
                position: absolute;
                left: 196px;
            }

            .text-style {
                position: absolute;
                top: 9px;
                right: 14px;
            }

            #demo-simple-select-standard-label {
                font-family: 'ActualR';
                font-size: 12px;

                color: #727373;
                cursor: pointer;
            }

            .heading {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .box_4 {
                width: 100%;
                height: 48px;
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                margin-top: 16px;

                .box_content {
                    padding: 5px 16px;

                    #value {
                        font-family: 'ActualR';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        /* identical to box height, or 133% */
                        /* Color/ Neutral/ Neutral - 500 */
                        color: #727373;
                    }

                }
            }


            .heading p {
                font-family: 'ActualR';
                font-style: normal;

                font-size: 14px;

                text-align: center;
                text-transform: capitalize;


                color: #2266DD;

                border-radius: 4px;

            }

            #head {
                font-family: 'ActualM';
                font-style: normal;

                font-size: 18px;

                color: #232323;

            }

            #switch {

                // display: flex;
                // justify-content: flex-start;
                // align-items: center;
                padding-top: 17px;

                .MuiSwitch-sizeMedium {
                    width: 48px;
                    height: 24px;
                    margin: 0px 16px 0px 11px;
                }

                .MuiSwitch-switchBase {
                    top: -1px;

                }

                .MuiSwitch-switchBase.Mui-checked {

                    left: 7px;
                }

                .MuiTypography-root {
                    font-family: 'ActualR';
                    font-size: 14px;
                    color: #333333;
                }
            }



            // #switch p {

            // font-family: 'ActualR';
            // font-style: normal;

            // font-size: 14px;

            // letter-spacing: 0.25px;

            // color: #333333;

            // opacity: 0.87;
            // }

            .toggle_group {
                margin-top: 16px;
                // min-width: 89px;
                width: 100%;

                .Mui-selected {
                    background-color: #48BC25;
                    color: #FFFFFF;
                    font-family: 'ActualR';
                    text-transform: none;
                    font-style: normal;

                    font-size: 16px;

                    /* identical to box height */

                    text-align: center;
                    letter-spacing: 0.5px;

                }

                .MuiToggleButton-root {
                    text-transform: none;
                    border: 1px solid rgba(102, 102, 102, 0.5);
                }

                .MuiToggleButtonGroup-root {
                    width: 100%;
                    border-radius: 4px;
                }

                .MuiToggleButtonGroup-grouped {
                    width: 100%;
                    min-height: 48px;
                    padding: 0px 1px;

                    // max-width: 89px;
                    // width: 100%;

                    // margin-right: -2px;
                    // padding: 0px 21px;
                    // min-height: 48px;
                    // padding: 0px 21px;
                    // height: 100%;

                }

            }

            .require_fields {
                font-family: 'ActualR';
                font-style: normal;

                font-size: 14px;

                letter-spacing: 0.25px;
            }

            .first_dropdown {

                .first_dropdown_1 {
                    margin-top: 16px;

                    .btn-marg {
                        margin: 0px;
                        height: 32px;
                        width: auto;
                        border: none;
                    }

                    .dropdown_1 {
                        border: 1px solid #AAAAAA;
                        border-radius: 4px;
                        // width: 100%;
                    }

                    #demo-simple-select-standard {
                        padding-top: 20px;
                    }

                    .MuiFormControl-root {
                        width: 100%;
                    }

                    .MuiFilledInput-root {
                        background-color: #FFFFFF;
                        width: 100%;
                        font-family: 'ActualR';
                        font-size: 14px;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        border-bottom-left-radius: 4px;


                    }

                    .MuiFilledInput-root:before {
                        border-bottom: none;

                    }

                    .MuiFilledInput-root:after {
                        border-bottom: none;
                        background-color: #FFFFFF;

                    }

                    .MuiSelect-icon {
                        color: #5029DD;

                    }
                }

            }

            .second_dropdown {

                .first_dropdown_1 {
                    margin-top: 16px;

                    .btn-marg {
                        margin: 0px;
                        height: 32px;
                        width: auto;
                        border: none;
                    }

                    .dropdown_1 {
                        margin-right: 4px;
                        border: 1px solid #AAAAAA;
                        border-radius: 4px;
                        width: 100%;
                    }

                    #demo-simple-select-standard {
                        padding-top: 20px;
                    }

                    .MuiFormControl-root {
                        width: 100%;
                    }

                    .MuiFilledInput-root {
                        background-color: #FFFFFF;
                        width: 100%;
                        font-family: 'ActualR';
                        font-size: 14px;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        border-bottom-left-radius: 4px;


                    }


                    .MuiFilledInput-root:before {
                        border-bottom: none;

                    }

                    .MuiFilledInput-root:after {
                        border-bottom: none;
                        background-color: #FFFFFF;

                    }

                    .MuiSelect-icon {
                        color: #5029DD;

                    }
                }

            }

            .main_content_switch {
                margin-top: 24px;

                #switch_1 {

                    // display: flex;
                    // justify-content: flex-start;
                    // align-items: center;
                    .MuiSwitch-sizeMedium {
                        width: 48px;
                        height: 24px;
                        margin: 0px 16px 0px 11px;
                    }

                    .MuiSwitch-switchBase {
                        top: -1px;

                    }

                    .MuiSwitch-switchBase.Mui-checked {
                        left: 7px;
                    }

                    .MuiTypography-root {
                        font-family: 'ActualR';
                        font-size: 12px;
                        color: #000000;
                    }

                    // .MuiSwitch-track {
                    // background-color: #48BC25;
                    // }
                }

                #switch_2 {
                    margin-top: 24px;

                    .MuiSwitch-sizeMedium {
                        width: 48px;
                        height: 24px;
                        margin: 0px 16px 0px 11px;
                    }

                    .MuiSwitch-switchBase {
                        top: -1px;

                    }

                    .MuiSwitch-switchBase.Mui-checked {
                        left: 7px;
                    }

                    .MuiTypography-root {
                        font-family: 'ActualR';
                        font-size: 12px;
                        color: #000000;
                    }

                    // .MuiSwitch-track {
                    // background-color: #48BC25;
                    // }

                }

                #switch_3 {
                    margin-top: 24px;

                    .MuiSwitch-sizeMedium {
                        width: 48px;
                        height: 24px;
                        margin: 0px 16px 0px 11px;
                    }

                    .MuiSwitch-switchBase {
                        top: -1px;

                    }

                    .MuiSwitch-switchBase.Mui-checked {
                        left: 7px;
                    }

                    .MuiTypography-root {
                        font-family: 'ActualR';
                        font-size: 12px;
                        color: #000000;
                    }

                    // .MuiSwitch-track {
                    // background-color: #48BC25;
                    // }

                }
            }




            .second_dropdown_1 {
                margin-top: 24px;

                .dropdown_1 {
                    margin-right: 4px;
                    border: 1px solid #AAAAAA;
                    border-radius: 4px;
                    width: 100%;
                }

                #demo-simple-select-standard {
                    padding-top: 20px;
                }

                .MuiFormControl-root {
                    width: 100%;
                }

                .MuiFilledInput-root {
                    background-color: #FFFFFF;
                    width: 100%;
                    font-family: 'ActualR';
                    font-size: 14px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;


                }

                .MuiSelect-icon {
                    color: #5029DD;
                }


                .MuiFilledInput-root:before {
                    border-bottom: none;

                }

                .MuiFilledInput-root:after {
                    border-bottom: none;
                    background-color: #FFFFFF;

                }

            }

        }

        .left_button {
            display: flex;
            justify-content: flex-end;

            margin-bottom: 16px;

            .MuiButtonBase-root.button-var2_new {
                color: white;
                background: #48BC25;
                ;
                text-transform: unset;
                // width: 100%;
                // max-width: 149px;
                width: 149px;
                height: 40px;
                margin: 15px 15px;
                font-size: 13px;
                letter-spacing: 0.1px;
                font-family: 'ActualR';
                box-shadow: none;
            }

        }
    }

    .right-side-component {
        margin-top: 24px;

        .project-detail-files-component {


            .project_file_hr {
                margin-top: 24px;
                margin-bottom: 16px;
            }

            // .MuiButtonBase-root-MuiButtonBase-root:hover {
            //     background-color:none;
            //     box-shadow: #fff !important;
            //     border: none !important;
            //     margin: none;
            //     padding: none;
            //     text-decoration: none;

            // }
            .MuiButton-root {
                background-color: #fff;
                box-shadow: none !important;

            }

            // .MuiDataGrid-root .MuiSwitch-switchBase .MuiSwitch-input:hover {
            //     background-color: none !important;
            //     }
            //     .MuiDataGrid-root.MuiSwitch-input:hover {
            //     background-color: none !important;
            //     }
            //     .MuiDataGrid-root .MuiSwitch-switchBase .MuiSwitch-input {
            //     background-color:  !important;
            //     }
            #demo-multiple-checkbox {
                width: 166px;
            }

            .MuiDataGrid-columnHeader {
                padding: 0px;
            }

            #select_partner {
                margin-left: -8px;

                .MuiOutlinedInput-root {
                    height: 34px;
                    width: 201px;
                }
            }

            .MuiIconButton-root {
                padding: 1px;
                margin-left: -8px;
            }

            .MuiDataGrid-columnHeaderTitleContainer {
                padding: 10px;
            }

            .css-nnbavb {
                float: left !important;
            }

            .MuiDataGrid-cell.MuiDataGrid-cell--textCenter.left-align,
            .left-align {
                padding: 10px;
            }

            .projectList-width {
                .MuiDialog-container.MuiDialog-scrollPaper {

                    .MuiPaper-root-MuiDialog-paper,
                    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24 {
                        width: 100% !important;
                        height: 100% !important;
                    }
                }

                // .MuiButtonBase-root-MuiIconButton-root:hover{
                //     background:none !important;
                // }
                // .button:hover {
                //     background-color:transparent !important;
                // }




            }

            .MuiIconButton-root:hover {
                background-color: transparent !important;
                background: none;
                text-decoration: none;
            }

            .ForwardRef\(Switch\)-root-1 {

                .MuiSwitch-switchBase:hover {
                    background-color: transparent !important;
                    background: none;
                    text-decoration: none;
                }

            }


            .MuiDataGrid-columnSeparator {
                visibility: hidden;

            }

            .MuiBox-root .MuiCheckbox-root {
                color: rgb(110, 110, 110) !important;
            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
            }

            .no-result {
                height: auto;
                border: 1px solid #aaa;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px;

                p {
                    font-family: actualM;
                    font-size: 16px;
                }
            }

            .header {
                max-width: 100%;
                // max-height:40px;

                .header_top {
                    display: flex;
                    justify-content: space-between;

                }

                .MuiButtonBase-root.button-var2_file_1stbutton {
                    color: white;
                    background: #48BC25;
                    ;
                    text-transform: unset;
                    // width: 100%;
                    // max-width: 149px;
                    width: 149px;
                    height: 40px;
                    font-size: 13px;
                    letter-spacing: 0.1px;
                    font-family: 'ActualR';
                    cursor: pointer;
                    box-shadow: none;
                    display: flex;
                    justify-content: center;

                }

                .MuiButtonBase-root.button-var2_file_2ndbutton {
                    color: #5029DD;
                    background: #FFFFFF;
                    border: 1px solid#5029DD;
                    text-transform: unset;
                    // width: 100%;
                    // max-width: 149px;
                    width: 149px;
                    height: 40px;
                    font-size: 13px;
                    letter-spacing: 0.1px;
                    font-family: 'ActualR';
                    cursor: pointer;
                    box-shadow: none;

                }
            }

            .MuiDataGrid-virtualScroller {
                overflow: hidden;
            }

            .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
                background-color: #fff;
            }

            .MuiDataGrid-root .MuiDataGrid-row:hover {
                background-color: #fff;
            }

            .MuiDataGrid-root .MuiDataGrid-row.Mui-selected {
                background-color: #fff;
            }

            .MuiDataGrid-columnHeaderTitle {
                font-family: 'ActualR';
                font-size: 12px;
                letter-spacing: 0.25px;
                color: #333333;
            }

            .heading {
                margin-top: 8px;
                font-family: 'ActualR';
                font-style: normal;

                font-size: 18px;


                color: #232323;

            }

            .button {
                display: flex;
            }

            .button-1st {
                padding-right: 16px;
            }

            .middle_part {
                // max-width: 768px;
                width: 100%;
                padding: 0px;

                .top-header {
                    height: 40px;
                    // min-width: 768px;
                    width: 100%;
                    background-color: var(--color-1);
                    border-radius: 4px 4px 0 0;
                    margin-top: 24px;
                }

                .MuiDataGrid-columnHeaders {
                    background-color: #F1F1F1;
                }

                @media only screen and (min-width: 1200px) {
                    .middle_part {
                        max-width: 1774px;

                    }
                }

                .MuiContainer-root {
                    padding: 0px;
                }

                #down_1 {
                    min-width: 135px;
                    width: 100%;
                    border: 1px solid #AAAAAA;
                    border-radius: 4px;
                    height: 32px;

                    #standard-drop {
                        padding-top: 0px;
                        padding-bottom: 0px;

                    }

                    #main_drop_place_text {
                        align-items: center;
                        font-family: 'ActualR';
                        font-style: normal;
                        font-size: 14px;
                        /* identical to box height */

                        letter-spacing: 0.25px;

                        color: #333333;
                        position: absolute;
                        padding-top: 5px;
                    }

                    .MuiFormControl-root {
                        width: 100%;
                    }

                    .MuiSelect-icon {
                        color: #5029DD;
                        top: calc(71% - 0.5em);
                    }

                    .MuiFilledInput-root {
                        background-color: #FFFFFF;
                        width: 100%;
                        font-family: 'ActualR';
                        font-size: 14px;
                        border-bottom: 1px solid #FFFFFF;

                    }


                    .MuiFilledInput-root:before {
                        border-bottom: 1px solid #FFFFFF;

                    }

                    .MuiFilledInput-root:after {
                        border-bottom: 1px solid #FFFFFF;
                        background-color: #FFFFFF;

                    }
                }

            }

            #select_partner {
                .MuiFormControl-root {
                    min-width: 104px;
                    width: 100%;
                    /* border: 1px solid #AAAAAA; */
                    border-radius: 4px;
                    /* height: 41px; */

                }

                #demo-multiple-checkbox {
                    padding: 3.5px 14px;
                }
            }

            .bill-pdf {
                font-family: 'ActualR';
                font-style: normal;

                font-size: 12px;


                letter-spacing: 0.25px;

                color: #2266DD;
                cursor: pointer;
                text-overflow: ellipsis;
                overflow: hidden;

            }

            .MuiButton-root {
                font-family: "ActualR";
                font-style: normal;
                font-size: 12px;
                letter-spacing: .25px;
                color: #26d;
                -webkit-justify-content: none;
                justify-content: left;
            }

            .date {
                font-family: 'ActualR';
                font-style: normal;

                font-size: 12px;


                letter-spacing: 0.25px;


                color: #666666;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .buttom_view {
                font-family: 'ActualR';
                font-style: normal;

                font-size: 12px;


                letter-spacing: 0.25px;

                color: #2266DD;
                cursor: pointer;
            }


        }

        .project-detail-products-component {





            #header_part {
                display: flex;
                justify-content: space-between;
                // max-width: 735px;
                // height: 40px;
                width: 100%;
            }



            #header_p {
                font-family: 'ActualM';
                font-style: normal;
                font-size: 18px;

                color: #232323;
                padding: 8px 0px 9px 0px;

            }

            .MuiButtonBase-root.button-var2_product {
                color: white;
                background: #48BC25;
                ;
                text-transform: unset;
                // width: 100%;
                // max-width: 149px;
                width: 149px;
                height: 40px;
                font-size: 13px;
                letter-spacing: 0.1px;
                font-family: 'ActualR';
                box-shadow: none;
            }

            #view {
                align-items: center;
                padding: 8px 16px;

                width: 100%;
                // height: 40px;

                background: #48BC25;
                border-radius: 6px;

                .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
                    font-family: 'ActualR';

                    font-size: 14px;


                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.1px;

                    color: #FFFFFF;


                }

                .css-1d6wzja-MuiButton-startIcon {
                    margin-left: 16px;
                    margin-top: 11px;
                    margin-bottom: 11px;
                }

            }

            #interest {
                margin-top: 16px;

            }

            #interest p {
                font-family: 'ActualR';
                font-style: normal;

                font-size: 12px;


                letter-spacing: 0.25px;

                color: #333333;

            }

            #outlined-helperText-label {
                position: absolute;
                left: 0;
                top: -4px;
            }


            .list {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                margin-top: 16px;

                .product-list {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-right: 16px;

                    .dot {
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #666666;
                        margin-right: 6px;
                    }

                    .list_name {
                        font-family: 'ActualR';
                        font-style: normal;

                        font-size: 14px;

                        letter-spacing: 0.25px;


                        color: #666666;

                    }
                }
            }

            .main_box {
                margin-top: 38px;
                max-width: 100%;
                // max-height: 246px;


                .box_module {
                    border: 1px solid #AAAAAA;
                    ;
                    ;
                    border-radius: 5px;
                    margin-top: 38px;
                    width: 100%;
                    padding: 16px 0px 16px 0px;

                    #value {
                        font-family: 'ActualR';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        /* identical to box height, or 133% */

                        /* Color/ Neutral/ Neutral - 500 */

                        color: #727373;

                    }

                    #dollar {
                        font-family: 'ActualR';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        /* identical to box height, or 133% */

                        /* Color/ Neutral/ Neutral - 500 */

                        color: #232323;
                    }


                    #heading_name {

                        font-family: 'ActualM';
                        font-style: normal;

                        font-size: 18px;

                        color: #232323;
                        margin-left: 16px;
                        text-transform: capitalize;

                    }
                }

                #middle_part {
                    margin-top: 8px;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: row;

                    gap: 24px;
                    margin-left: 16px;
                    margin-right: 120px;

                    .MuiTextField-root {
                        width: 100%;
                        // height: 48px;
                    }

                    .input_field {
                        // height: 48px;
                        // width: 288px;
                        // padding: 5px 16px 5px 16px;
                        // border: 1px solid #AAAAAA;
                        // border-radius: 4px;
                        // height: 51px;
                        /* width: 288px; */
                        height: 48px;
                        padding: 5px 16px 5px 16px;
                        border: 1px solid #AAAAAA;
                        border-radius: 4px;
                        width: 100%;

                        .MuiFilledInput-root {
                            background-color: #FFFFFF;
                            width: 100%;
                            font-family: 'ActualR';
                            font-size: 14px;
                            // border-bottom: 1px solid #AAAAAA
                        }

                        .MuiFilledInput-root:before {
                            border-bottom: 1px solid #FFFFFF;

                        }

                        .MuiFilledInput-root:after {
                            border-bottom: 1px solid #FFFFFF;
                            background-color: #FFFFFF;

                        }

                    }
                }

                .box_module_2 {
                    border: 1px solid #AAAAAA;
                    ;
                    border-radius: 5px;
                    margin-top: 24px;
                    max-width: 100%;
                    padding: 16px 0px 16px 0px;

                    #heading_name_1 {
                        font-family: 'ActualM';
                        font-style: normal;

                        font-size: 18px;



                        color: #232323;
                        margin-left: 16px;

                    }
                }

                #middle_part_1 {
                    margin-top: 8px;
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: row;

                    gap: 24px;
                    margin-left: 16px;
                    margin-right: 120px;

                    .MuiTextField-root {
                        width: 100%;
                        // height: 48px;
                    }

                    .input_field {
                        // height: 48px;
                        // width: 288px;
                        // padding: 5px 16px 5px 16px;
                        // border: 1px solid #AAAAAA;
                        // border-radius: 4px;
                        height: 48px;
                        padding: 5px 16px 5px 16px;
                        border: 1px solid #AAAAAA;
                        border-radius: 4px;
                        width: 100%;

                        .MuiFilledInput-root {
                            background-color: #FFFFFF;
                            width: 100%;
                            font-family: 'ActualR';
                            font-size: 14px;
                            // border-bottom: 1px solid #AAAAAA
                        }

                        .MuiFilledInput-root:before {
                            border-bottom: 1px solid #FFFFFF;

                        }

                        .MuiFilledInput-root:after {
                            border-bottom: 1px solid #FFFFFF;
                            background-color: #FFFFFF;

                        }

                    }
                }
            }



        }

        .project-detail-Notes-component {
            .MuiTextField-root {
                width: 100%;
                // height: 48px;
            }

            .input_field {

                // height: 48px;
                // width: 288px;
                // padding: 5px 16px 5px 16px;
                // border: 1px solid #AAAAAA;
                // border-radius: 4px;
                // height: 51px;
                /* width: 288px; */
                // height: 134px;
                // border: 1px solid #AAAAAA;
                // border-radius: 4px;
                // width: 100%;
                .MuiFilledInput-root {
                    background-color: #FFFFFF;
                    max-width: 100%;
                    font-family: 'ActualR';
                    font-size: 14px;
                    // border-bottom: 1px solid #AAAAAA
                }

                .tagline {
                    position: absolute;
                    /* left: -5px; */
                    padding-left: 16px;
                    padding-top: 5px;
                    font-family: 'ActualR';
                    font-weight: 400;
                    font-size: 12px;



                    color: #727373;
                }

                .text {
                    padding: 0px;
                    margin: 0px;
                    height: 110px;
                    border: 1px solid #AAAAAA;
                    border-radius: 4px;
                    width: 98%;
                    padding-top: 20px;
                    padding-left: 3px;
                    font-family: 'ActualR';
                    font-weight: 400;
                    font-size: 14px;
                    padding-left: 16px;
                }

                .MuiFilledInput-root:before {
                    border-bottom: 1px solid #FFFFFF;
                }

                .MuiFilledInput-root:after {
                    border-bottom: 1px solid #FFFFFF;
                    background-color: #FFFFFF;
                }
            }

            .project_file__notes_hr {
                margin-top: 24px;
                margin-bottom: 24px;
            }

            .Notes_header_text {
                font-family: 'ActualM';
                font-style: normal;
                font-size: 18px;
                color: #232323;
            }

            .notes_middle_part {
                width: 100%;
                padding: 0px;
                margin-top: 16px;
                // border: 1px solid rgba(102, 102, 102, 0.5);
                border: 1px solid #AAAAAA;

                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;

                @media only screen and (min-width: 1200px) {
                    .middle_part_notes {
                        max-width: 1774px;
                    }
                }

                .MuiTabs-scroller {
                    background-color: #FFF;
                    padding-top: 7px;
                    border-bottom: 1px solid #AAAAAA;
                }

                .css-1kt0o6l {
                    border-bottom: none;
                }

                .Mui-selected {
                    color: #5029DD;
                }

                .MuiTabs-indicator {
                    background-color: #5029DD;
                }

                .tab {
                    padding: 16px 40px;
                    font-family: 'ActualR';
                    font-size: 14px;
                    text-align: center;
                    letter-spacing: 0.25px;
                    text-transform: capitalize;
                }

                // .tab_panel {
                // margin: 16px;
                // // max-width: 703px;
                // height: 134px;
                // border: 1px solid #AAAAAA;
                // border-radius: 4px;
                // }
                #tab_text {
                    height: 134px;
                    max-width: 100%;
                    /* border-radius: 4px; */
                    border: 1px solid #aaa;
                    border-radius: 4px;
                    font-family: 'ActualR';
                    font-style: normal;
                    font-weight: 400;
                    // font-size: 12px;
                    padding: 24px;
                    color: #727373;
                }

                #tab_text_second {
                    font-family: 'ActualR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #AAAAAA;
                }

                .button_notes {
                    display: flex;
                    justify-content: flex-end;
                }

                .project_file__notes_hr_1 {
                    margin-top: 0px !important;
                    margin-bottom: 16px;
                }

                .MuiButtonBase-root.button-var2_file_1stbutton {
                    color: white;
                    background: #48BC25;
                    ;
                    text-transform: unset;
                    // width: 100%;
                    // max-width: 149px;
                    width: 149px;
                    height: 40px;
                    font-size: 13px;
                    letter-spacing: 0.1px;
                    font-family: 'ActualR';
                    cursor: pointer;
                    margin: 0px 16px 16px 0px;
                    box-shadow: none;
                    ;
                }
            }
        }

        .project-detail-Linked-component {
            .MuiDataGrid-row.Mui-selected {
                background-color: none;
            }

            .MuiDataGrid-row.Mui-selected:hover {
                background-color: none;
            }

            .MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
                background-color: #fff;
            }

            .MuiDataGrid-row.Mui-selected {
                background-color: #fff;
            }

            .project_Linked_hr {
                margin-top: 24px;
                margin-bottom: 24px;
            }

            .MuiDataGrid-columnSeparator {
                visibility: hidden;

            }

            .merge-text {
                font-family: 'ActualR';
                font-size: 12px;
                color: #2266DD;
                cursor: pointer;
                text-transform: capitalize;

            }

            .Linked_header_text {
                font-family: 'ActualM';
                font-style: normal;
                font-size: 18px;
                color: #232323;
            }

            .Linked_middle_part {
                width: 100%;
                padding: 0px;
                margin-top: 16px;

                @media only screen and (min-width: 1200px) {
                    .Linked_middle_part {
                        max-width: 100% !important;

                    }


                }

                .top-header {
                    height: 40px;
                    // min-width: 768px;
                    width: 100%;
                    background-color: var(--color-1);
                    border-radius: 4px 4px 0 0;
                    margin-top: 24px;
                }

                .MuiDataGrid-virtualScroller {
                    overflow: hidden;

                }

                .MuiDataGrid-columnHeaders {
                    background-color: #F1F1F1;
                    ;
                }

                .bill-pdf {
                    font-family: 'ActualR';
                    font-style: normal;

                    font-size: 12px;


                    letter-spacing: 0.25px;

                    // color: #2266DD;
                    cursor: pointer;
                    text-transform: capitalize;
                }

                .MuiDataGrid-columnHeaderTitle {
                    font-family: 'ActualR';
                    font-size: 12px;
                    letter-spacing: 0.25px;
                    color: #333333;
                }

                .date {
                    font-family: 'ActualR';
                    font-size: 12px;
                    letter-spacing: 0.25px;
                    color: #666666;
                    text-transform: capitalize;

                }

                .buttom_view {
                    font-family: 'ActualR';
                    font-style: normal;

                    font-size: 12px;


                    letter-spacing: 0.25px;

                    color: #2266DD;
                    cursor: pointer;
                }

            }
        }

        .project-detail-activity-component {
            .project_activity_hr {
                margin-top: 24px;
                margin-bottom: 24px;
            }

            .activity_header_text {
                font-family: 'ActualM';
                font-style: normal;
                font-size: 18px;
                color: #232323;
            }

            .MuiDataGrid-columnSeparator {
                visibility: hidden;

            }

            .notes_middle_part_activity {
                width: 100%;
                padding: 0px;
                margin-top: 16px;
                border: 1px solid rgba(102, 102, 102, 0.5);
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;

                @media only screen and (min-width: 1200px) {
                    .middle_part_notes {
                        max-width: 1774px;

                    }

                    .top-header {
                        height: 20px;
                        // min-width: 710px;
                        width: 100%;
                        background-color: var(--color-1);

                        margin-top: 16px;
                    }

                }

                .top-header {
                    height: 20px;

                    width: 100%;
                    background-color: var(--color-1);

                    margin-top: 16px;
                    padding: 12px 0px;
                    border-radius: 0px;
                }

                .MuiBox-root {
                    padding: 0px;
                }

                .MuiDataGrid-columnHeaders {
                    background-color: #F1F1F1;
                    ;
                }

                .MuiTabs-scroller {
                    background-color: #FFF;
                    padding-top: 7px;
                    border-bottom: 1px solid #AAAAAA;
                }

                .css-1kt0o6l {
                    border-bottom: none;
                }

                .Mui-selected {
                    color: #5029DD;
                }

                .MuiTabs-indicator {
                    background-color: #5029DD;
                }

                .tab {
                    padding: 16px 40px;
                    font-family: 'ActualR';
                    font-size: 14px;


                    text-align: center;
                    letter-spacing: 0.25px;
                    text-transform: capitalize;




                }

                // .tab_panel {
                // margin: 16px;
                // max-width: 703px;
                // height: 134px;
                // border: 1px solid #AAAAAA;
                // border-radius: 4px;
                // }

                #tab_text {
                    font-family: 'ActualR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;


                    color: #727373;

                }

                .MuiDataGrid-columnHeaderTitle {
                    font-family: 'ActualR';
                    font-size: 12px;
                    letter-spacing: 0.25px;
                    color: #333333;
                }

                .date {
                    font-family: 'ActualR';
                    font-size: 12px;
                    letter-spacing: 0.25px;
                    color: #666666
                }

                .bill-pdf {
                    font-family: 'ActualR';
                    font-size: 12px;
                    letter-spacing: 0.25px;
                    color: #666666
                }

                #tab_text_second {
                    font-family: 'ActualR';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;



                    color: #AAAAAA;
                }

            }

        }
    }
}