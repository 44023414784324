.SOLUTION {
    .heading {
        font-family: 'ActualR';
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        color: #232323;
    }

    .buttom_group {
        width: 260px;
        margin-left: 411px;


        .toggle_group {
            margin-top: 16px;
            // min-width: 89px;
            width: 100%;

            .Mui-selected {
                background-color: #48BC25;
                color: #FFFFFF;
                font-family: 'ActualR';
                text-transform: none;
                font-style: normal;

                font-size: 16px;

                /* identical to box height */

                text-align: center;
                letter-spacing: 0.5px;

            }

            .MuiToggleButton-root {
                text-transform: none;
                border: 1px solid rgba(102, 102, 102, 0.5);
            }

            .MuiToggleButtonGroup-root {
                width: 100%;
                border-radius: 4px;
            }

            .MuiToggleButtonGroup-grouped {
                width: 100%;
                min-height: 48px;
                padding: 0px 1px;

                // max-width: 89px;
                // width: 100%;

                // margin-right: -2px;
                // padding: 0px 21px;
                // min-height: 48px;
                // padding: 0px 21px;
                // height: 100%;

            }

        }
    }

    .header_middle_part {
        margin-top: 24px;
        display: flex;
        justify-content: flex-start;

        .dropdown_solution {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 224px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }

        .add_button {
            margin-left: 19px;


            .button-var2_header-left {
                color: #fff;
                background: #48bc25;
                text-transform: unset;
                width: 87px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualR";
                box-shadow: none;
            }

            .MuiButton-root:hover {
                background-color: #48bc25;
                box-shadow: none;
                text-decoration: none;
            }
        }

        .text_field {

            width: 224px;
            height: 48px;
            border: 1px solid #aaa;
            border-radius: 4px;
            margin-left: 16px;

            .box_content {
                padding: 5px 16px;
            }

            #value {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #727373;
            }

            #dollar {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #232323;
            }


        }

        .check_box {
            display: flex;

            .check_p {
                margin-left: 2px;
                margin-top: 14px;
                margin-right: 6px;
                font-family: 'ActualR';

                font-weight: 400;
                font-size: 14px;


                color: #333333;
            }

            .MuiCheckbox-root {
                color: rgb(110, 110, 110) !important;

            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
            }

        }


    }

    .header_middle_part_secondhalf {
        .heading_text {
            margin-top: 24px;
            font-family: 'ActualM';

            font-weight: 500;
            font-size: 18px;

            color: #232323;
        }

        .middle_part {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;

            .text-filed {

                width: 334px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;

                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }


            }

            .process_button {


                .button-var2_header-right {
                    color: #fff;
                    background: #c4e0bc;
                    text-transform: unset;
                    width: 212px;
                    height: 48px;
                    font-size: 13px;
                    letter-spacing: .1px;
                    font-family: "ActualR";
                    box-shadow: none;


                    .MuiButton-root:hover {
                        background-color: #48bc25;
                        box-shadow: none;
                        text-decoration: none;
                    }
                }

            }
        }
    }

    .notes_hr {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .header_middle_part_bottompart {
        .heading_text {
            font-family: 'ActualM';

            font-weight: 500;
            font-size: 18px;

            color: #232323;
        }

        .middle_part {
            display: flex;
            justify-content: space-between;
            margin-top: 16px;

            .left_part {
                display: flex;

                .text-filed {

                    width: 334px;
                    height: 48px;
                    border: 1px solid #aaa;
                    border-radius: 4px;

                    .box_content {
                        padding: 5px 16px;
                    }

                    #value {
                        font-family: "ActualR";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        color: #727373;
                    }

                    #dollar {
                        font-family: "ActualR";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: #232323;
                    }


                }

                .button_2 {
                    margin-left: 24px;

                    .button-var2_System-Data {
                        color: #5029DD;
                        background: #FFFFFF;
                        text-transform: unset;
                        width: 140px;
                        height: 48px;
                        font-size: 13px;
                        letter-spacing: .1px;
                        font-family: "ActualM";
                        box-shadow: none;
                        border: 1px solid #5029DD;
                        border-radius: 6px;
                    }

                    .button-var2_Rerun {
                        color: #5029DD;
                        background: #FFFFFF;
                        text-transform: unset;
                        width: 102px;
                        height: 48px;
                        font-size: 13px;
                        letter-spacing: .1px;
                        font-family: "ActualM";
                        box-shadow: none;
                        border: 1px solid #5029DD;
                        border-radius: 6px;
                    }

                    .button-var2_Edit_Manually {
                        color: #5029DD;
                        background: #FFFFFF;
                        text-transform: unset;
                        width: 151px;
                        height: 48px;
                        font-size: 13px;
                        letter-spacing: .1px;
                        font-family: "ActualM";
                        box-shadow: none;
                        border: 1px solid #5029DD;
                        border-radius: 6px;
                    }
                }
            }

            .right_part {
                display: flex;

                .check_box {
                    display: flex;
                    margin-right: 24px;

                    .check_p {
                        margin-left: 2px;
                        margin-top: 14px;
                        margin-right: 6px;
                        font-family: 'ActualR';

                        font-weight: 400;
                        font-size: 14px;


                        color: #333333;
                    }

                    .MuiCheckbox-root {
                        color: rgb(110, 110, 110) !important;

                    }

                    .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                        color: var(--color-2) !important;
                    }

                }

                .dropdown_Generate_Bids {
                    border: 1px solid #AAAAAA;
                    border-radius: 4px;
                    width: 264px;
                    height: 48px;

                    // .MuiFilledInput-input {
                    //     padding-top: 20px;
                    // }

                    #demo-simple-select-standard {
                        padding-top: 20px;
                    }

                    .MuiFormControl-root {
                        width: 100%;
                    }

                    .MuiFilledInput-root {
                        background-color: #FFFFFF;
                        width: 100%;
                        font-family: 'ActualR';
                        font-size: 14px;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        border-bottom-left-radius: 4px;

                    }

                    .MuiFilledInput-root:before {
                        border-bottom: none;

                    }

                    .MuiFilledInput-root:after {
                        border-bottom: none;
                        background-color: #FFFFFF;

                    }

                    .MuiSelect-icon {
                        color: #5029DD;

                    }

                    .MuiInputBase-sizeSmall {
                        padding-bottom: 3px;
                    }

                    .MuiInputLabel-root {
                        font-family: 'ActualR';
                        font-weight: 400;
                        font-size: 14px;



                        color: #666666;
                    }
                }
            }


        }
    }

    .according {
        .MuiAccordion-root {
            background: #333333;
            margin-bottom: 24px;
            border-radius: 4px;
            font-family: 'ActualR';

            font-weight: 400;
            font-size: 14px;

            color: #FFFFFF;
            padding: 0px;
        }

        .MuiAccordionSummary-expandIconWrapper {
            color: #FFFFFF;
        }

        .MuiAccordionDetails-root {
            padding: 0px 0 !important;
        }

        // .MuiAccordionSummary-content.Mui-expanded{
        //     margin: 12px;
        // }
        .MuiAccordionSummary-root.Mui-expanded {
            max-height: 48px;
            min-height: 0px;
        }

    }
}

.MuiDialogTitle-root {
    height: 12px !important;
    background: #333333;

    font-family: 'ActualR';

    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;
}

// .MuiDialog-paper {
//     max-width: 1212px !important;
// width: 100%;
// }
// .MuiDialogContent-root {
//     padding: 24px;
// }
.MuiDialogTitle-root {
    margin: 0;
    font-family: 'ActualR' !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: none !important;
    letter-spacing: none !important;

}

.MuiCollapse-root {
    margin-bottom: 24px !important;
}

#submit_buttom {
    display: flex;
    justify-content: flex-end;
    margin-top: 0px;
    margin-bottom: 16px;
    margin-right: 16px;

    .button-var2_submit {
        color: #fff;
        background: #48bc25;
        text-transform: unset;
        width: 191px;
        height: 46px;
        font-size: 13px;
        letter-spacing: .1px;
        font-family: "ActualM";
        box-shadow: none;

    }

    .energy_name {

        font-family: 'ActualR';

        font-weight: 400;
        font-size: 13px;
        color: #FFFFFF;
    }

    .MuiButton-root:hover {
        background-color: #48bc25;
        box-shadow: none;
        text-decoration: none;
    }

}

.system {
    background: #fff;
    padding: 24px;
    color: #232323;

    .notes_tag {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .header {

        display: flex;
        justify-content: space-between;

        h1 {
            font-family: 'ActualM';

            font-weight: 500;
            font-size: 18px;



            color: #232323;
        }

        .Energy_calculator_button {
            .button-var2_header-left {
                color: #fff;
                background: #48bc25;
                text-transform: unset;
                width: 180px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualM";
                box-shadow: none;
            }

            .energy_name {

                font-family: 'ActualR';

                font-weight: 400;
                font-size: 13px;
                color: #FFFFFF;
            }

            .MuiButton-root:hover {
                background-color: #48bc25;
                box-shadow: none;
                text-decoration: none;
            }

        }

    }

    .notes {
        margin-top: 16px;
        margin-bottom: 24px;
    }

    .middle_part_1 {
        display: flex;
        justify-content: flex-start;

        .box_1 {
            .text-filed {

                width: 329px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;
                background: #F1F1F1;

                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }


            }

        }

        .box_2 {
            margin-left: 16px;

            .text-filed {

                width: 329px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;
                background: #F1F1F1;


                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }


            }

        }

        .box_3 {
            margin-left: 16px;

            .text-filed {

                width: 329px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;



                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }


            }

        }
    }

    .middle_part_2 {
        display: flex;
        justify-content: flex-start;
        margin-top: 24px;

        .box_1 {
            .text-filed {

                width: 329px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;


                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }


            }

        }

        .box_2 {
            margin-left: 16px;

            .text-filed {

                width: 329px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;



                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }


            }

        }

        .box_3 {
            margin-left: 16px;

            .text-filed {

                width: 329px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;



                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }


            }

        }
    }


    .project-detail-files-component {
        .MuiDataGrid-columnSeparator {
            visibility: hidden;

        }

        .MuiContainer-root {
            padding-left: 0px;
            padding-right: 0px;
        }

        .MuiDataGrid-columnHeaders {
            background-color: #F1F1F1;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.25px;
            color: #333333;
            height: 20px;

        }

        .MuiDataGrid-cell {
            background: #fff;
        }

        // .MuiDataGrid-columnHeaderTitle{
        //     overflow: none;
        //     white-space: normal;
        //     word-wrap: break-word;
        //     margin-left: auto;
        //     margin-right: auto;
        //     margin-top: auto;
        //     margin-bottom: auto;f

        // } 









        .top-header {
            padding: 12px 16px;
            background-color: var(--color-1);
            color: white;
            border-radius: 4px 4px 0 0;
            margin-top: 20px;
            height: 40px;
            font-family: 'ActualR';

            font-weight: 400;
            font-size: 14px;

            color: #FFFFFF;
        }

        .MuiDataGrid-virtualScroller {
            overflow: hidden;
        }

        .Aurora-Design {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 142px;
            height: 32px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
                padding-top: 0px;
                padding-left: 0px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 11px;
                line-height: 20px;
                color: #232323;
                position: absolute;
                left: -4px;
                top: -8px;
            }

            .MuiAutocomplete-endAdornment {
                right: 2px;
                top: 2px;
            }

            .MuiFilledInput-input {
                padding: 4.5px 4px !important;
            }


        }

        .MuiDataGrid-virtualScroller {
            overflow: hidden;
        }

        .panel {

            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #666666;
        }

        #switch {

            // display: flex;
            // justify-content: flex-start;
            // align-items: center;
            // padding-top: 17px;

            .MuiSwitch-sizeMedium {
                width: 48px;
                height: 24px;
                margin: 0px 16px 0px 11px;
            }

            .MuiSwitch-switchBase {
                top: -1px;

            }

            .MuiSwitch-switchBase.Mui-checked {

                left: 7px;
            }

            .MuiTypography-root {
                font-family: 'ActualR';
                font-size: 14px;
                color: #333333;
            }
        }
    }

    .energy {
        font-family: 'ActualM';
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;

    }

    .footer {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;

        .footer_left {
            display: flex;

            .dropdown_solution {
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 320px;
                height: 48px;

                // .MuiFilledInput-input {
                //     padding-top: 20px;
                // }

                #demo-simple-select-standard {
                    padding-top: 20px;
                }

                .MuiFormControl-root {
                    width: 100%;
                }

                .MuiFilledInput-root {
                    background-color: #FFFFFF;
                    width: 100%;
                    font-family: 'ActualR';
                    font-size: 14px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;

                }

                .MuiFilledInput-root:before {
                    border-bottom: none;

                }

                .MuiFilledInput-root:after {
                    border-bottom: none;
                    background-color: #FFFFFF;

                }

                .MuiSelect-icon {
                    color: #5029DD;

                }

                .MuiInputBase-sizeSmall {
                    padding-bottom: 3px;
                }

                .MuiInputLabel-root {
                    font-family: 'ActualR';
                    font-weight: 400;
                    font-size: 14px;



                    color: #666666;
                }
            }

            .footer_input {
                margin-left: 16px;

                .text-filed {

                    width: 329px;
                    height: 48px;
                    border: 1px solid #aaa;
                    border-radius: 4px;


                    .box_content {
                        padding: 5px 16px;
                    }

                    #value {
                        font-family: "ActualR";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        color: #727373;
                    }

                    #dollar {
                        font-family: "ActualR";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: #232323;
                    }


                }
            }

        }

        .footer_right {

            .button-var2_header-left {
                color: #fff;
                background: #48bc25;
                text-transform: unset;
                width: 188px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualM";
                box-shadow: none;
            }

            .MuiButton-root:hover {
                background-color: #48bc25;
                box-shadow: none;
                text-decoration: none;
            }



        }
    }






}

.energy_calculate {

    .energy_calculate_header_part {
        display: flex;
        margin-top: 16px;
        justify-content: flex-start;

        .make {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 220px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }

        .model {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 220px;
            height: 48px;
            margin-left: 16px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }

        .yearly_milage {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 220px;
            height: 48px;
            margin-left: 16px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }

        .Electric {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 142px;
            height: 48px;
            margin-left: 16px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }

        .home {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 142px;
            height: 48px;
            margin-left: 16px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }

        .Qty {
            margin-left: 16px;

            .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
                font: inherit;
                letter-spacing: inherit;
                color: currentColor;
                padding: 4px 0 5px;
                border: 0;
                box-sizing: content-box;
                background: none;
                height: 1.4375em;
                margin: 0;
                -webkit-tap-highlight-color: transparent;
                display: block;
                min-width: 0;
                width: 100%;
                -webkit-animation-name: mui-auto-fill-cancel;
                animation-name: mui-auto-fill-cancel;
                -webkit-animation-duration: 10ms;
                animation-duration: 10ms;
                padding: 0px;
                margin-left: 1px;
            }

            .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                text-align: left;
                position: absolute;
                bottom: 0;
                right: 0;
                top: -5px;
                left: 0;
                margin: 0;
                padding: 0 8px;
                pointer-events: none;
                border-radius: inherit;
                border-style: solid;
                border-width: none !important;
                overflow: hidden;
                min-width: 0%;
                border-color: #fff !important;
            }

            .text-filed {

                width: 140px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;

                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }
            }

        }

        .buttom {
            .button {
                margin-top: 16px;
                padding: 0px !important;
            }
        }

    }

    .notes {
        margin-top: 24px;
        margin-bottom: 24px;
    }

}

.Applications {
    .energy_calculate_header_part {
        display: flex;
        margin-top: 16px;
        justify-content: flex-start;

        .make {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 220px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }

        .model {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 220px;
            height: 48px;
            margin-left: 16px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }

        .yearly_milage {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 220px;
            height: 48px;
            margin-left: 16px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }

        .Qty {

            margin-left: 16px;

            .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
                font: inherit;
                letter-spacing: inherit;
                color: currentColor;
                padding: 0px;
                border: 0;
                box-sizing: content-box;
                background: none;
                height: 1.4375em;
                margin: 0;
                -webkit-tap-highlight-color: transparent;
                display: block;
                min-width: 0;
                width: 100%;
                -webkit-animation-name: mui-auto-fill-cancel;
                animation-name: mui-auto-fill-cancel;
                -webkit-animation-duration: 10ms;
                animation-duration: 10ms;
                padding: 0px;
                margin-left: 1px;
            }

            .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
                text-align: left;
                position: absolute;
                bottom: 0;
                right: 0;
                top: -5px;
                left: 0;
                margin: 0;
                padding: 0 8px;
                pointer-events: none;
                border-radius: inherit;
                border-style: solid;
                border-width: none !important;
                overflow: hidden;
                min-width: 0%;
                border-color: #fff !important;
            }

            .text-filed {

                width: 140px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;

                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }
            }

        }

        .buttom {
            .button {
                margin-top: 16px;
                padding: 0px !important;
            }
        }

        .toggler {
            margin-left: 16px;

            .eff {
                font-family: 'ActualR';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;


                color: #333333;
            }

            #switch {

                // display: flex;
                // justify-content: flex-start;
                // align-items: center;
                padding-top: 4px;

                .MuiSwitch-sizeMedium {
                    width: 48px;
                    height: 24px;
                    margin: 0px 16px 0px 11px;
                }

                .MuiSwitch-switchBase {
                    top: -1px;

                }

                .MuiSwitch-switchBase.Mui-checked {

                    left: 7px;
                }

                .MuiTypography-root {
                    font-family: 'ActualR';
                    font-size: 14px;
                    color: #333333;
                }

                .MuiFormControlLabel-root {
                    margin-left: 12px;
                    margin-right: 0px;
                }
            }
        }
    }

    .notes {
        margin-top: 24px;
        margin-bottom: 24px;
    }

}

.Thirddropdown {

    .notes {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .energy_calculate_header_part {
        display: flex;

        justify-content: flex-start;

        .make {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 220px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }

        .model {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 220px;
            height: 48px;
            margin-left: 16px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }
    }

}

.Incentive {
    background: #fff;
    padding: 24px;
    color: #232323;

    .header {
        display: flex;
        justify-content: flex-end;

        .refresh {


            .button-var2_header-left {
                color: #fff;
                background: #48bc25;
                text-transform: unset;
                width: 114px;
                height: 48px;
                font-size: 14px;
                letter-spacing: .1px;
                font-family: "ActualM";
                box-shadow: none;
            }

            .MuiButton-root:hover {
                background-color: #48bc25;
                box-shadow: none;
                text-decoration: none;
            }
        }

    }

    .middle_part {
        margin-top: 24px;

        .project-detail-files-component {
            .MuiDataGrid-columnSeparator {
                visibility: hidden;

            }

            .MuiContainer-root {
                padding-left: 0px;
                padding-right: 0px;
            }

            .MuiDataGrid-columnHeaders {
                background-color: #F1F1F1;
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.25px;
                color: #333333;
                height: 20px;

            }

            .MuiDataGrid-cell {
                background: #fff;
            }

            // .MuiDataGrid-columnHeaderTitle{
            //     overflow: none;
            //     white-space: normal;
            //     word-wrap: break-word;
            //     margin-left: auto;
            //     margin-right: auto;
            //     margin-top: auto;
            //     margin-bottom: auto;f

            // } 









            .top-header {
                padding: 12px 16px;
                background-color: var(--color-1);
                color: white;
                border-radius: 4px 4px 0 0;
                margin-top: 20px;
                height: 40px;
                font-family: 'ActualR';

                font-weight: 400;
                font-size: 14px;

                color: #FFFFFF;
            }

            .MuiDataGrid-virtualScroller {
                overflow: hidden;
            }

            .Aurora-Design {
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 142px;
                height: 32px;

                // .MuiFilledInput-input {
                //     padding-top: 20px;
                // }

                #demo-simple-select-standard {
                    padding-top: 20px;
                }

                .MuiFormControl-root {
                    width: 100%;
                }

                .MuiFilledInput-root {
                    background-color: #FFFFFF;
                    width: 100%;
                    font-family: 'ActualR';
                    font-size: 14px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                    padding-top: 0px;
                    padding-left: 0px;

                }

                .MuiFilledInput-root:before {
                    border-bottom: none;

                }

                .MuiFilledInput-root:after {
                    border-bottom: none;
                    background-color: #FFFFFF;

                }

                .MuiSelect-icon {
                    color: #5029DD;

                }

                .MuiInputLabel-root {
                    font-family: 'ActualR';
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 20px;
                    color: #232323;
                    position: absolute;
                    left: -4px;
                    top: -8px;
                }

                .MuiAutocomplete-endAdornment {
                    right: 0px;
                    top: 2px;
                }



            }

            .MuiDataGrid-virtualScroller {
                overflow: hidden;
            }

            .panel {

                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #666666;
            }

            #switch {

                // display: flex;
                // justify-content: flex-start;
                // align-items: center;
                padding-top: 17px;

                .MuiSwitch-sizeMedium {
                    width: 48px;
                    height: 24px;
                    margin: 0px 16px 0px 11px;
                }

                .MuiSwitch-switchBase {
                    top: -1px;

                }

                .MuiSwitch-switchBase.Mui-checked {

                    left: 7px;
                }

                .MuiTypography-root {
                    font-family: 'ActualR';
                    font-size: 14px;
                    color: #333333;
                }
            }

            #check {
                .MuiCheckbox-root {
                    color: #333333 !important;
                }

                color: #333333 !important;
            }

            .MuiBox-root .MuiCheckbox-root {
                color: rgb(110, 110, 110) !important;
                padding: 0px;
            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
            }
        }

    }

    .middle_second_part {
        margin-top: 24px;

        .project-detail-files-component {
            .MuiDataGrid-columnSeparator {
                visibility: hidden;

            }

            .MuiContainer-root {
                padding-left: 0px;
                padding-right: 0px;
            }

            .MuiDataGrid-columnHeaders {
                background-color: #F1F1F1;
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.25px;
                color: #333333;
                height: 20px;

            }

            .MuiDataGrid-cell {
                background: #fff;
            }

            // .MuiDataGrid-columnHeaderTitle{
            //     overflow: none;
            //     white-space: normal;
            //     word-wrap: break-word;
            //     margin-left: auto;
            //     margin-right: auto;
            //     margin-top: auto;
            //     margin-bottom: auto;f

            // } 









            .top-header {
                padding: 12px 16px;
                background-color: var(--color-1);
                color: white;
                border-radius: 4px 4px 0 0;
                margin-top: 20px;
                height: 40px;
                font-family: 'ActualR';

                font-weight: 400;
                font-size: 14px;

                color: #FFFFFF;
            }

            .MuiDataGrid-virtualScroller {
                overflow: hidden;
            }

            .Aurora-Design {
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 142px;
                height: 32px;

                // .MuiFilledInput-input {
                //     padding-top: 20px;
                // }

                #demo-simple-select-standard {
                    padding-top: 20px;
                }

                .MuiFormControl-root {
                    width: 100%;
                }

                .MuiFilledInput-root {
                    background-color: #FFFFFF;
                    width: 100%;
                    font-family: 'ActualR';
                    font-size: 14px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                    padding-top: 0px;
                    padding-left: 0px;

                }

                .MuiFilledInput-root:before {
                    border-bottom: none;

                }

                .MuiFilledInput-root:after {
                    border-bottom: none;
                    background-color: #FFFFFF;

                }

                .MuiSelect-icon {
                    color: #5029DD;

                }

                .MuiInputLabel-root {
                    font-family: 'ActualR';
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 20px;
                    color: #232323;
                    position: absolute;
                    left: -4px;
                    top: -8px;
                }

                .MuiAutocomplete-endAdornment {
                    right: 0px;
                    top: 2px;
                }



            }

            .MuiDataGrid-virtualScroller {
                overflow: hidden;
            }

            .panel {

                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #666666;
            }

            #switch {

                // display: flex;
                // justify-content: flex-start;
                // align-items: center;
                padding-top: 17px;

                .MuiSwitch-sizeMedium {
                    width: 48px;
                    height: 24px;
                    margin: 0px 16px 0px 11px;
                }

                .MuiSwitch-switchBase {
                    top: -1px;

                }

                .MuiSwitch-switchBase.Mui-checked {

                    left: 7px;
                }

                .MuiTypography-root {
                    font-family: 'ActualR';
                    font-size: 14px;
                    color: #333333;
                }
            }

            #check {
                .MuiCheckbox-root {
                    color: #333333 !important;
                }

                color: #333333 !important;
            }

            .MuiBox-root .MuiCheckbox-root {
                color: rgb(110, 110, 110) !important;
            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
            }
        }

        .notes_hr {
            margin-top: 24px;
            margin-bottom: 24px;
        }

    }

    .footer {
        .title {
            font-family: 'ActualR';
            font-weight: 500;
            font-size: 18px;



            color: #232323;
        }

        .footer_middle_part {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;

            .left_side {
                display: flex;
                justify-content: flex-start;

                .left_side_textfield {


                    .text {
                        padding: 0px;
                        margin: 0px;

                        height: 38px;
                        border: 1px solid #AAAAAA;
                        border-radius: 4px;
                        width: 320px;
                        padding: 5px 16px;


                        font-family: 'ActualR';
                        font-weight: 400;
                        font-size: 14px;

                    }

                }

                .right_side {
                    margin-left: 16px;


                    .text {
                        padding: 0px;
                        margin: 0px;

                        height: 38px;
                        border: 1px solid #AAAAAA;
                        border-radius: 4px;
                        width: 320px;
                        padding: 5px 16px;


                        font-family: 'ActualR';
                        font-weight: 400;
                        font-size: 14px;

                    }

                }
            }

            .right_side {
                .button-var2_header-left {
                    color: #fff;
                    background: #48bc25;
                    text-transform: unset;
                    width: 96px;
                    height: 48px;
                    font-size: 14px;
                    letter-spacing: .1px;
                    font-family: "ActualM";
                    box-shadow: none;
                }

                .MuiButton-root:hover {
                    background-color: #48bc25;
                    box-shadow: none;
                    text-decoration: none;
                }

            }


        }
    }



}

.Active_bundles {
    background: #fff;
    padding: 24px;
    color: #232323;
    display: flex;
    justify-content: flex-start;

}

.Adders {
    background: #fff;
    padding: 24px;
    color: #232323;

    .header {
        display: flex;
        justify-content: space-between;

        .left_header {
            display: flex;
            justify-content: flex-start;

            .tittle {
                font-family: 'ActualM';

                font-weight: 500;
                font-size: 18px;
                margin-top: 9px;

                color: #232323;
            }

            .check_box {
                display: flex;

                .check_p {
                    margin-left: 2px;
                    margin-top: 14px;
                    margin-right: 6px;
                    font-family: 'ActualR';

                    font-weight: 400;
                    font-size: 14px;


                    color: #333333;
                }

                .MuiCheckbox-root {
                    color: rgb(110, 110, 110) !important;

                }

                .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                    color: var(--color-2) !important;
                }

            }


        }

        .right_header {
            .button-var2_header-left {
                color: #fff;
                background: #48bc25;
                text-transform: unset;
                width: 174px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualR";
                box-shadow: none;
            }

            .MuiButton-root:hover {
                background-color: #48bc25;
                box-shadow: none;
                text-decoration: none;
            }

        }
    }

    .roofting {
        margin-top: 16px;

        .project-detail-files-component {
            .MuiFilledInput-input {
                padding: 4.5px 9px !important
            }

            .MuiAutocomplete-popupIndicator {
                margin-right: 3px;
            }

            .MuiContainer-root {
                padding-left: 0px;
                padding-right: 0px;
            }

            .MuiDataGrid-columnHeaders {
                background-color: #F1F1F1;
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.25px;
                color: #333333;
                height: 20px;

            }

            .MuiDataGrid-cell {
                background: #fff;
            }

            // .MuiDataGrid-columnHeaderTitle{
            //     overflow: none;
            //     white-space: normal;
            //     word-wrap: break-word;
            //     margin-left: auto;
            //     margin-right: auto;
            //     margin-top: auto;
            //     margin-bottom: auto;f

            // } 









            .top-header {
                padding: 12px 16px;
                background-color: var(--color-1);
                color: white;
                border-radius: 4px 4px 0 0;
                margin-top: 20px;
                height: 40px;
                font-family: 'ActualR';

                font-weight: 400;
                font-size: 14px;
                text-transform: capitalize;
                color: #FFFFFF;
            }

            .MuiDataGrid-virtualScroller {
                overflow: hidden;
            }

            .Aurora-Design {
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 177px;
                height: 32px;

                // .MuiFilledInput-input {
                //     padding-top: 20px;
                // }

                #demo-simple-select-standard {
                    padding-top: 20px;
                }

                .MuiFormControl-root {
                    width: 100%;
                }

                .MuiFilledInput-root {
                    background-color: #FFFFFF;
                    width: 100%;
                    font-family: 'ActualR';
                    font-size: 14px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                    padding-top: 0px;
                    padding-left: 0px;

                }

                .MuiFilledInput-root:before {
                    border-bottom: none;

                }

                .MuiFilledInput-root:after {
                    border-bottom: none;
                    background-color: #FFFFFF;

                }

                .MuiSelect-icon {
                    color: #5029DD;

                }

                .MuiInputLabel-root {
                    font-family: 'ActualR';
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 20px;
                    color: #232323;
                    position: absolute;
                    left: -4px;
                    top: -8px;
                }

                .MuiAutocomplete-endAdornment {
                    right: 0px;
                    top: 2px;
                }



            }

            .MuiDataGrid-virtualScroller {
                overflow: hidden;
            }

            .panel {

                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #666666;
            }

            #switch {

                // display: flex;
                // justify-content: flex-start;
                // align-items: center;
                padding-top: 17px;

                .MuiSwitch-sizeMedium {
                    width: 48px;
                    height: 24px;
                    margin: 0px 16px 0px 11px;
                }

                .MuiSwitch-switchBase {
                    top: -1px;

                }

                .MuiSwitch-switchBase.Mui-checked {

                    left: 7px;
                }

                .MuiTypography-root {
                    font-family: 'ActualR';
                    font-size: 14px;
                    color: #333333;
                }
            }

            .text {
                padding: 0px;
                margin: 0px;

                height: 32px;
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 177px;
                padding: 5px 16px;


                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;

            }

            .MuiCheckbox-root {
                color: #333333 !important;

            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
            }

            .MuiDataGrid-columnSeparator {
                visibility: hidden;

            }
        }
    }

    .notes_hr {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .ElectricAdder {
        .project-detail-files-component {
            .MuiFilledInput-input {
                padding: 4.5px 9px !important
            }

            .MuiAutocomplete-popupIndicator {
                margin-right: 3px;
            }

            .MuiContainer-root {
                padding-left: 0px;
                padding-right: 0px;
            }

            .MuiDataGrid-columnHeaders {
                background-color: #F1F1F1;
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.25px;
                color: #333333;
                height: 20px;

            }

            .MuiDataGrid-cell {
                background: #fff;
            }

            // .MuiDataGrid-columnHeaderTitle{
            //     overflow: none;
            //     white-space: normal;
            //     word-wrap: break-word;
            //     margin-left: auto;
            //     margin-right: auto;
            //     margin-top: auto;
            //     margin-bottom: auto;f

            // } 









            .top-header {
                padding: 12px 16px;
                background-color: var(--color-1);
                color: white;
                border-radius: 4px 4px 0 0;
                margin-top: 20px;
                height: 40px;
                font-family: 'ActualR';

                font-weight: 400;
                font-size: 14px;

                color: #FFFFFF;
            }

            .MuiDataGrid-virtualScroller {
                overflow: hidden;
            }

            .Aurora-Design {
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 177px;
                height: 32px;

                // .MuiFilledInput-input {
                //     padding-top: 20px;
                // }

                #demo-simple-select-standard {
                    padding-top: 20px;
                }

                .MuiFormControl-root {
                    width: 100%;
                }

                .MuiFilledInput-root {
                    background-color: #FFFFFF;
                    width: 100%;
                    font-family: 'ActualR';
                    font-size: 14px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                    padding-top: 0px;
                    padding-left: 0px;

                }

                .MuiFilledInput-root:before {
                    border-bottom: none;

                }

                .MuiFilledInput-root:after {
                    border-bottom: none;
                    background-color: #FFFFFF;

                }

                .MuiSelect-icon {
                    color: #5029DD;

                }

                .MuiInputLabel-root {
                    font-family: 'ActualR';
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 20px;
                    color: #232323;
                    position: absolute;
                    left: -4px;
                    top: -8px;
                }

                .MuiAutocomplete-endAdornment {
                    right: 0px;
                    top: 2px;
                }



            }

            .MuiDataGrid-virtualScroller {
                overflow: hidden;
            }

            .panel {

                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #666666;
            }

            #switch {

                // display: flex;
                // justify-content: flex-start;
                // align-items: center;
                padding-top: 17px;

                .MuiSwitch-sizeMedium {
                    width: 48px;
                    height: 24px;
                    margin: 0px 16px 0px 11px;
                }

                .MuiSwitch-switchBase {
                    top: -1px;

                }

                .MuiSwitch-switchBase.Mui-checked {

                    left: 7px;
                }

                .MuiTypography-root {
                    font-family: 'ActualR';
                    font-size: 14px;
                    color: #333333;
                }
            }

            .text {
                padding: 0px;
                margin: 0px;

                height: 32px;
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 177px;
                padding: 5px 16px;


                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;

            }

            .MuiCheckbox-root {
                color: #333333 !important;

            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
            }

            .MuiDataGrid-columnSeparator {
                visibility: hidden;

            }
        }
    }

    .AdditionalAdder {
        margin-top: 24px;

        .project-detail-files-component {
            .MuiFilledInput-input {
                padding: 4.5px 9px !important
            }

            .MuiAutocomplete-popupIndicator {
                margin-right: 3px;
            }

            .MuiContainer-root {
                padding-left: 0px;
                padding-right: 0px;
            }

            .MuiDataGrid-columnHeaders {
                background-color: #F1F1F1;
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.25px;
                color: #333333;
                height: 20px;

            }

            .MuiDataGrid-cell {
                background: #fff;
            }

            // .MuiDataGrid-columnHeaderTitle{
            //     overflow: none;
            //     white-space: normal;
            //     word-wrap: break-word;
            //     margin-left: auto;
            //     margin-right: auto;
            //     margin-top: auto;
            //     margin-bottom: auto;f

            // } 









            .top-header {
                padding: 12px 16px;
                background-color: var(--color-1);
                color: white;
                border-radius: 4px 4px 0 0;
                margin-top: 20px;
                height: 40px;
                font-family: 'ActualR';

                font-weight: 400;
                font-size: 14px;

                color: #FFFFFF;
            }

            .MuiDataGrid-virtualScroller {
                overflow: hidden;
            }

            .Aurora-Design {
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 177px;
                height: 32px;

                // .MuiFilledInput-input {
                //     padding-top: 20px;
                // }

                #demo-simple-select-standard {
                    padding-top: 20px;
                }

                .MuiFormControl-root {
                    width: 100%;
                }

                .MuiFilledInput-root {
                    background-color: #FFFFFF;
                    width: 100%;
                    font-family: 'ActualR';
                    font-size: 14px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                    padding-top: 0px;
                    padding-left: 0px;

                }

                .MuiFilledInput-root:before {
                    border-bottom: none;

                }

                .MuiFilledInput-root:after {
                    border-bottom: none;
                    background-color: #FFFFFF;

                }

                .MuiSelect-icon {
                    color: #5029DD;

                }

                .MuiInputLabel-root {
                    font-family: 'ActualR';
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 20px;
                    color: #232323;
                    position: absolute;
                    left: -4px;
                    top: -8px;
                }

                .MuiAutocomplete-endAdornment {
                    right: 0px;
                    top: 2px;
                }



            }

            .MuiDataGrid-virtualScroller {
                overflow: hidden;
            }

            .panel {

                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #666666;
            }

            #switch {

                // display: flex;
                // justify-content: flex-start;
                // align-items: center;
                padding-top: 17px;

                .MuiSwitch-sizeMedium {
                    width: 48px;
                    height: 24px;
                    margin: 0px 16px 0px 11px;
                }

                .MuiSwitch-switchBase {
                    top: -1px;

                }

                .MuiSwitch-switchBase.Mui-checked {

                    left: 7px;
                }

                .MuiTypography-root {
                    font-family: 'ActualR';
                    font-size: 14px;
                    color: #333333;
                }
            }

            .text {
                padding: 0px;
                margin: 0px;

                height: 32px;
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 177px;
                padding: 5px 16px;


                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;

            }

            .MuiCheckbox-root {
                color: #333333 !important;

            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
            }

            .MuiDataGrid-columnSeparator {
                visibility: hidden;

            }
        }
    }

    .footer {
        .text-filed {

            width: 334px;
            height: 48px;
            border: 1px solid #aaa;
            border-radius: 4px;

            .box_content {
                padding: 5px 16px;
            }

            #value {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #727373;
            }

            #dollar {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #232323;
            }


        }

        .footer_middle {
            display: flex;
            justify-content: flex-start;
            margin-top: 24px;

            .button_1 {
                .button-var2_System-Data {
                    color: #5029DD;
                    background: #FFFFFF;
                    text-transform: unset;
                    width: 194px;
                    height: 48px;
                    font-size: 13px;
                    letter-spacing: .1px;
                    font-family: "ActualM";
                    box-shadow: none;
                    border: 1px solid #5029DD;
                    border-radius: 6px;
                }
            }

            .button_2 {
                margin-left: 16px;

                .button-var2_System-Data {
                    color: #5029DD;
                    background: #FFFFFF;
                    text-transform: unset;
                    width: 194px;
                    height: 48px;
                    font-size: 13px;
                    letter-spacing: .1px;
                    font-family: "ActualM";
                    box-shadow: none;
                    border: 1px solid #5029DD;
                    border-radius: 6px;
                }
            }
        }

        .Calculate_Adder {
            margin-top: 24px;

            .button-var2_header-left {
                color: #fff;
                background: #48bc25;
                text-transform: unset;
                width: 192px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualM";
                box-shadow: none;
            }

            .MuiButton-root:hover {
                background-color: #48bc25;
                box-shadow: none;
                text-decoration: none;
            }

        }

    }


}

.add_popup {
    .header_part {
        display: flex;
        justify-content: space-between;

        .tittle {
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;

            color: #333333;
            margin-top: 10px;
        }

        .right_side {


            .text {
                padding: 0px;
                margin: 0px;

                height: 38px;
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 320px;
                padding: 5px 16px;


                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;

            }



        }
    }

    .middle_part {
        .check_box {
            display: flex;

            .check_p {
                margin-left: 2px;
                margin-top: 14px;
                margin-right: 6px;
                font-family: 'ActualR';

                font-weight: 400;
                font-size: 14px;


                color: #333333;
            }

            .MuiCheckbox-root {
                color: rgb(110, 110, 110) !important;

            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
            }

        }

    }

    .middle_part_2 {
        display: flex;
        margin-top: 16px;
        justify-content: space-between;

        .tittle {
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;

            color: #333333;
            margin-top: 10px;

        }

        .dropdown_solution {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 352px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }


    }

    .footer {
        margin-top: 24px;
        display: flex;
        justify-content: center;

        .button-var2_header-left {
            color: #fff;
            background: #48bc25;
            text-transform: unset;
            width: 192px;
            height: 48px;
            font-size: 13px;
            letter-spacing: .1px;
            font-family: "ActualM";
            box-shadow: none;
        }

        .MuiButton-root:hover {
            background-color: #48bc25;
            box-shadow: none;
            text-decoration: none;
        }


    }
}

#add_design {
    .header_part {
        #headline {
            font-family: 'ActualR' !important;
            font-weight: 400 !important;
            font-size: 14px !important;

            color: #333333 !important;
            margin-top: 24px !important;

        }

        .left_side {


            .text {

                height: 38px;
                border: 1px solid #AAAAAA;
                border-radius: 4px;
                width: 320px;
                padding: 5px 16px;


                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;

            }



        }
    }

    .footer {
        margin-top: 24px;
        display: flex;

        .buttom_1 {


            .button-var2_header-left {
                color: #fff;
                background: #48bc25;
                text-transform: unset;
                width: 191px;
                height: 48px;
                font-size: 13px;
                letter-spacing: .1px;
                font-family: "ActualM";
                box-shadow: none;
            }

            .MuiButton-root:hover {
                background-color: #48bc25;
                box-shadow: none;
                text-decoration: none;
            }
        }

        .button-var2_header-left_right {
            margin-left: 20px;
            color: #2266DD;
            background: #fff;
            text-transform: unset;
            width: 191px;
            height: 48px;
            font-size: 13px;
            letter-spacing: .1px;
            font-family: "ActualM";
            box-shadow: none;
            border: 2px solid #2266DD;

        }

        .MuiButton-root:hover {
            background-color: #fff;
            box-shadow: none;
            text-decoration: none;
        }


    }
}

.Adders_calculator {
    .MuiTabs-scroller {
        margin-right: 0px;
        background: none;
        padding-top: 0px;
        padding-left: 0px;
    }

    .MuiTab-root {
        padding: 7px 40px;
        font-family: 'ActualR';
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #666666;
    }

    .MuiTab-root.Mui-selected {
        color: #5029DD;
    }

    .tab {
        text-transform: capitalize;
    }

    .MuiTabs-indicator {
        background-color: #5029DD;
        left: 27px !important;
        width: 131.338px !important;
    }

    .MuiBox-root {
        border-color: #C4C4C4;

    }

    .Solutions {
        .check_box {
            display: flex;

            .check_p {
                margin-left: 2px;
                // margin-top: 14px;
                margin-right: 34px;
                font-family: 'ActualR';

                font-weight: 400;
                font-size: 14px;


                color: #333333;
            }

            .MuiCheckbox-root {
                color: rgb(110, 110, 110) !important;
                padding: 0px !important;


            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
                padding: 0px !important;

            }

        }

        .middle_part_1 {
            display: flex;
            justify-content: flex-start;
            margin-top: 16px;

            .text_field {

                width: 222px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;

                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }


            }

            .text_field_1 {

                width: 222px;
                height: 48px;
                border: 1px solid #aaa;
                border-radius: 4px;
                margin-left: 24px;

                .box_content {
                    padding: 5px 16px;
                }

                #value {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    color: #727373;
                }

                #dollar {
                    font-family: "ActualR";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #232323;
                }


            }



        }

        .notes_hr {
            margin-top: 24px;
            margin-bottom: 24px;
        }

    }
}

.custom_quote {
    padding: 24px;

    .first_dropdown {
        .dropdown_solution {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 232px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }
    }

    .project-detail-files-component {
        .MuiDataGrid-columnSeparator {
            visibility: hidden;

        }

        .MuiContainer-root {
            padding-left: 0px;
            padding-right: 0px;
        }

        .MuiDataGrid-columnHeaders {
            background-color: #F1F1F1;
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.25px;
            color: #333333;
            height: 20px;

        }

        .MuiDataGrid-cell {
            background: #fff;
        }

        // .MuiDataGrid-columnHeaderTitle{
        //     overflow: none;
        //     white-space: normal;
        //     word-wrap: break-word;
        //     margin-left: auto;
        //     margin-right: auto;
        //     margin-top: auto;
        //     margin-bottom: auto;f

        // } 









        .top-header {
            padding: 12px 16px;
            background-color: var(--color-1);
            color: white;
            border-radius: 4px 4px 0 0;
            margin-top: 20px;
            height: 40px;
            font-family: 'ActualR';

            font-weight: 400;
            font-size: 14px;

            color: #FFFFFF;
        }

        .MuiDataGrid-virtualScroller {
            overflow: hidden;
        }

        .Aurora-Design {
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 157px;
            height: 32px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;
                padding-top: 0px;
                padding-left: 0px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 11px;
                line-height: 20px;
                color: #232323;
                position: absolute;
                left: -4px;
                top: -8px;
            }

            .MuiAutocomplete-endAdornment {
                right: 2px;
                top: 2px;
            }

            .MuiFilledInput-input {
                padding: 4.5px 4px !important;
            }


        }

        .MuiDataGrid-virtualScroller {
            overflow: hidden;
        }

        .panel {

            font-family: 'ActualR';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #666666;
        }

        #switch {

            // display: flex;
            // justify-content: flex-start;
            // align-items: center;
            padding-top: 17px;

            .MuiSwitch-sizeMedium {
                width: 48px;
                height: 24px;
                margin: 0px 16px 0px 11px;
            }

            .MuiSwitch-switchBase {
                top: -1px;

            }

            .MuiSwitch-switchBase.Mui-checked {

                left: 7px;
            }

            .MuiTypography-root {
                font-family: 'ActualR';
                font-size: 14px;
                color: #333333;
            }
        }
    }

    .notes_hr {
        margin-top: 20px;
        margin-bottom: 16px;
    }

    #submit_buttom {
        display: flex;
        justify-content: flex-end;
        margin-top: 0px;
        margin-bottom: 16px;
        margin-right: 16px;

        .button-var2_submit {
            color: #fff;
            background: #48bc25;
            text-transform: unset;
            width: 191px;
            height: 46px;
            font-size: 13px;
            letter-spacing: .1px;
            font-family: "ActualM";
            box-shadow: none;

        }

        .energy_name {

            font-family: 'ActualR';

            font-weight: 400;
            font-size: 13px;
            color: #FFFFFF;
        }

        .MuiButton-root:hover {
            background-color: #48bc25;
            box-shadow: none;
            text-decoration: none;
        }

    }
}

.misc_adder {
    padding: 24px;

    .text-filed {

        width: 322px;
        height: 48px;
        border: 1px solid #aaa;
        border-radius: 4px;

        .box_content {
            padding: 5px 16px;
        }

        #value {
            font-family: "ActualR";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #727373;
        }

        #dollar {
            font-family: "ActualR";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #232323;
        }


    }

    .middle {
        margin-top: 24px;
        display: flex;
        justify-content: flex-start;

        .text-filed {

            width: 322px;
            height: 48px;
            border: 1px solid #aaa;
            border-radius: 4px;

            .box_content {
                padding: 5px 16px;
            }

            #value {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #727373;
            }

            #dollar {
                font-family: "ActualR";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #232323;
            }


        }

        .dropdown_solution {
            margin-left: 16px;
            border: 1px solid #AAAAAA;
            border-radius: 4px;
            width: 322px;
            height: 48px;

            // .MuiFilledInput-input {
            //     padding-top: 20px;
            // }

            #demo-simple-select-standard {
                padding-top: 20px;
            }

            .MuiFormControl-root {
                width: 100%;
            }

            .MuiFilledInput-root {
                background-color: #FFFFFF;
                width: 100%;
                font-family: 'ActualR';
                font-size: 14px;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-bottom-left-radius: 4px;

            }

            .MuiFilledInput-root:before {
                border-bottom: none;

            }

            .MuiFilledInput-root:after {
                border-bottom: none;
                background-color: #FFFFFF;

            }

            .MuiSelect-icon {
                color: #5029DD;

            }

            .MuiInputBase-sizeSmall {
                padding-bottom: 3px;
            }

            .MuiInputLabel-root {
                font-family: 'ActualR';
                font-weight: 400;
                font-size: 14px;



                color: #666666;
            }
        }
    }

    .note_hr {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    .file_upload {
        display: flex;
        justify-content: flex-start;

        .dropzone {
            text-align: center;
            padding: 40px 20px;
            border: 1px #dee2e6 solid;
            width: 386px;
            margin: auto;
        }
    }

    .footer_part {
        .title_footer {
            font-family: 'ActualR';
            font-weight: 400;
            font-size: 18px;
            color: #232323;
            margin-bottom: 24px;
        }

        .check_box {
            display: flex;

            .check_p {
                margin-left: 2px;
                margin-top: 10px;
                margin-right: 6px;
                font-family: 'ActualM';

                font-weight: 500;
                font-size: 14px;


                color: #333333;
            }

            .MuiCheckbox-root {
                color: #AAAAAA !important;

            }

            .MuiCheckbox-root.MuiButtonBase-root.Mui-checked {
                color: var(--color-2) !important;
            }

        }
    }

}